<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('journals_setting.journals_setting') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('journals_setting.document_name') }}<span class="text-danger">*</span></label>
                <select name="document_id" v-if="doc_id" disabled id="document_id" v-model="data.document_id" class="custom-select" :class="validation && validation.document_id ? 'is-invalid' : ''">
                  <option v-for="row in documents_list" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
                <multiselect v-else v-model="document"
                             :placeholder="$t('journals_setting.document_name')"
                             label="name"
                             track-by="id"
                             @input="changeDocumentId"
                             :options="documents_list"
                             :multiple="false"
                             :taggable="true"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.document_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.document_id[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('journals_setting.journal_title') }}<span class="text-danger">*</span></label>
                <input v-model="data.journal_title" class="form-control" :class="validation && validation.journal_title ? 'is-invalid' : ''">
                <span v-if="validation && validation.journal_title" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.journal_title[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5 mt-5" id="journals-setting-radio">
                <b-form-group id="radio-group-2" :label="$t('journals_setting.journal_type')" v-slot="{ ariaDescribedby }">
                  <b-form-radio v-model="data.journal_type" v-for="(row, index) in journal_type_list"
                                :aria-describedby="index" name="journal_type" :value="row.id" :key="index">{{ row.title }}
                  </b-form-radio>
                </b-form-group>

                <span v-if="validation && validation.journal_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.journal_type[0] }}
                            </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('journals_setting.status') }}</label>
                <select name="client_layout" :disabled="true" id="client_layout" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="row in status_list" :value="row.id" :selected="data.status == row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_active" :class="validation && validation.is_active ? 'is-invalid' : ''" name="check-button" switch>
                  {{ $t('journals_setting.is_active') }}
                </b-form-checkbox>
                <span v-if="validation && validation.is_active" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.is_active[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="row mt-5">
            <div class="col-md-12">
              <div class="d-flex justify-content-between mt-2 p-2 bg-FFB803" style="height: 45px;">
                <h6 class="my-auto text-white">{{ $t('journals_setting.journal') }}</h6>
                <button type="button" class="btn btn-primary btn-sm" v-if="(details.length < 2 && data.journal_type == 1) || data.journal_type == 2" @click="addItemRowToList">{{ $t('add_more') }}</button>
              </div>
            </div>
            <div class="col-md-12">
              <div class="table-responsive bg-white">
                <table class="table table-row-bordered w-max-content" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList(0)">
                  <thead>
                  <tr>
                    <th width="200px">{{ $t('journals_setting.column') }}</th>
                    <th width="200px">{{ $t('journals_setting.general_account') }}</th>
                    <th width="100px">{{ $t('journals_setting.type') }}</th>
                    <th width="100px">{{ $t('journals_setting.depend_on') + ' 1' }}</th>
                    <th width="100px">{{ $t('journals_setting.depend_on') + ' 2' }}</th>
                    <th width="160px">{{ $t('journals_setting.account_type') }}</th>
                    <th width="300px">{{ $t('journals_setting.special_account') }}</th>
                    <th></th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in details" :key="index">
                    <td>
                      <multiselect v-model="row.column"
                                   disabled
                                   :placeholder="$t('journals_setting.columns')"
                                   :options="doc_id ? setDocType : columns_doc_list"
                                   :multiple="false"
                                   :taggable="true"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   @input="selectColumn(index)"
                                   :show-no-results="false">
                        <!-- isDisabled -->
                      </multiselect>

                    </td>
                    <td>
                      <treeselect
                          :options="accounts_list"
                          :load-options="loadOptions"
                          disabled
                          :multiple="false"
                          :value="row.general_account_id"
                          @input="updateValue(index, 'general_account_id', $event)"
                          :searchable="true"
                          :show-count="true"
                          :no-children-text="$t('No_sub_options')"
                          :no-options-text="$t('No_options_available')"
                          :no-results-text="$t('No_results_found')"
                          :placeholder="$t('Select')">
                      </treeselect>
                    </td>
                    <td>
                      <select v-model="row.type" class="custom-select">
                        <option v-for="row in type_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                      </select>
                    </td>
                    <td>
<!--                      base_1-->

                      <div v-for="(_base_1, _index_base_1) in base_1" :key="'base_1'+_index_base_1">
                        <b-form-checkbox :id="_base_1.slug+index" v-model="row[_base_1.slug]" :name="_base_1.slug+index" :value="1" :unchecked-value="0">
                          {{ _base_1.title }}
                        </b-form-checkbox>
                      </div>
<!--                      <div>-->
<!--                        <b-form-checkbox :id="'branch_'+index" v-model="row.is_branch" :name="'branch_'+index" :value="1" :unchecked-value="0">-->
<!--                          {{ $t('journals_setting.branch') }}-->
<!--                        </b-form-checkbox>-->
<!--                      </div>-->
<!--                      <div>-->
<!--                        <b-form-checkbox :id="'inventory_'+index" v-model="row.is_inventory" :name="'inventory_'+index" :value="1" :unchecked-value="0">-->
<!--                          {{ $t('journals_setting.inventory') }}-->
<!--                        </b-form-checkbox>-->
<!--                      </div>-->
<!--                      <div>-->
<!--                        <b-form-checkbox :id="'currency_'+index" v-model="row.is_currency" :name="'currency_'+index" :value="1" :unchecked-value="0">-->
<!--                          {{ $t('journals_setting.currency') }}-->
<!--                        </b-form-checkbox>-->
<!--                      </div>-->

                    </td>
                    <td>
<!--                      base_2-->
                      <div>
<!--                        <b-form-group label="" v-slot="{ ariaDescribedby }">-->
                          <b-form-radio  v-for="(_base_2, _index_base_2) in base_2" :key="'base_2'+_index_base_2" :id="_base_2.slug+index" v-model="row[_base_2.slug]" @change="changeDependentOn(index, _base_2.slug)" :name="'dependent_on_'+index" :value="1">
                            {{ _base_2.title }}
                          </b-form-radio>
<!--                          <b-form-radio :id="'customer_'+index" v-model="row.is_customer" @change="changeDependentOn(index, 'is_customer')" :name="'dependent_on_'+index" :aria-describedby="ariaDescribedby" :value="1">-->
<!--                            {{ $t('journals_setting.customer') }}-->
<!--                          </b-form-radio>-->
<!--                          <b-form-radio :id="'vendor_'+index" v-model="row.is_vendor" @change="changeDependentOn(index, 'is_vendor')" :name="'dependent_on_'+index" :aria-describedby="ariaDescribedby" :value="1">-->
<!--                            {{ $t('journals_setting.vendor') }}-->
<!--                          </b-form-radio>-->
<!--                          <b-form-radio :id="'item_'+index" v-model="row.is_item" @change="changeDependentOn(index, 'is_item')" :name="'dependent_on_'+index" :aria-describedby="ariaDescribedby" :value="1">-->
<!--                            {{ $t('journals_setting.item') }}-->
<!--                          </b-form-radio>-->
<!--                        </b-form-group>-->

                      </div>
                    </td>
                    <td>
                      <select v-model="row.account_type" class="custom-select" disabled>
                        <option v-for="row in account_type_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                      </select>
                    </td>
                    <td>
                      <!--                                        <treeselect-->
                      <!--                                                :options="accounts_list"-->
                      <!--                                                :load-options="loadOptions"-->
                      <!--                                                :multiple="false"-->
                      <!--                                                :value="row.special_account_id"-->
                      <!--                                                @input="updateValue(index, 'special_account_id', $event)"-->
                      <!--                                                :searchable="true"-->
                      <!--                                                :show-count="true"-->
                      <!--                                                :no-children-text="$t('No_sub_options')"-->
                      <!--                                                :no-options-text="$t('No_options_available')"-->
                      <!--                                                :no-results-text="$t('No_results_found')"-->
                      <!--                                                :placeholder="$t('Select')">-->
                      <!--                                        </treeselect>-->
                      <multiselect
                          v-model="row.special_account"
                          @input="updateValue(index, 'special_account_id', $event)"
                          disabled
                          label="label"
                          track-by="id"
                          :placeholder="$t('journals_setting.special_account')"
                          :options="accounts_leaf_list"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getAccounts($event)">
                      </multiselect>
                    </td>
                    <td>
                      <v-icon style="color: #dc3545;" small v-if="details.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        <div class="pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6 mt-5">
              <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            </div>
          </div>
        </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-journals-setting",
  data() {
    return {
      mainRoute: 'accounting/journal_settings',
      subMainRoute: 'accounting/document_type_settings',
      mainRouteDependency: 'base/dependency',

      documents_list: [],
      status_list: [],
      columns_doc_list: [],

      journal_type_list: [
        {id: 1, title: this.$t('journals_setting.simple')},
        {id: 2, title: this.$t('journals_setting.complex')},
      ],

      accounts_list: [],
      accounts_leaf_list: [],

      type_list: [
        {id: 0, title: this.$t('journals_setting.none')},
        {id: 1, title: this.$t('journals_setting.decrease')},
        {id: 2, title: this.$t('journals_setting.increase')},
      ],
      account_type_list: [
        {id: 1, title: this.$t('journals_setting.credit')},
        {id: 2, title: this.$t('journals_setting.debit')},
      ],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      doc_id: this.$route.query.doc_id,

      document: null,
      data: {
        document_id: null,
        journal_title: null,
        journal_type: 1,
        status: 1,
        is_active: true,
      },
      isEditing: false,
      validation: null,
      details: [],
      isDisabled: false,
      setColumnName: null,
      details_form: {
        column: null,
        column_id: null,
        column_name: null,
        general_account_id: null,
        special_account_id: null,
        type: 0,
        is_branch: 0,
        is_inventory: 0,
        is_customer: 0,
        is_vendor: 0,
        is_item: 0,
        is_currency: 0,
        account_type: 1,//credit ,debit
        key_id: null,
        key_slug: null,
      },
      base_1: [],
      base_2: [],
    };
  },

  watch: {
    document: function (val) {
      if (val) {
        this.data.document_id = val.id;
      } else {
        this.data.document_id = null;
      }
    },
    'data.document_id': function (val) {
      if (val) {
        let _doc = this.documents_list.find((row) => row.id == val);
        if (_doc) {
          this.getStatusList(_doc.doc_type);

          this.data.status = _doc.status;
          if (this.$route.query.document_id) {
            this.columns_doc_list = _doc.columns_doc;
          } else {
            this.getColumnsDocList(val);
          }
        }
      } else {
        this.status_list = [];
        this.columns_doc_list = [];
      }
    },
  },
  methods: {
    changeDocumentId: _.debounce(function (){
      this.getDocumentTypeSettings()
    }, 100),
    save() {
      let _credit = false;
      let _debit = false;
      this.details.forEach((row) => {
        if (row.account_type == 1) {
          _credit = true;
        }
        if (row.account_type == 2) {
          _debit = true;
        }

      });
      if (_credit && _debit) {
        if (this.isEditing) {
          this.update();
        } else {
          this.create();
        }
      } else {
        this.$warningMessage(this.$t('the_entries_should_contain_at_least_one_credit_and_one_debit'));
      }

    },

    create() {

      ApiService.post(this.mainRoute, {
        ...this.data,
        details: this.details,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/journals-setting');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        details: this.details,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/journals-setting');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.document_id = response.data.data.document_id;
        this.document = response.data.data.document;
        this.data.journal_title = response.data.data.journal_title;
        this.data.journal_type = response.data.data.journal_type;
        this.data.status = response.data.data.status;
        this.data.is_active = (response.data.data.is_active ? true : false);

        if (response.data.data.details) {
          this.details = response.data.data.details;
        } else {
          this.addItemRowToList();
        }
      });
    },
    getDocumentTypeSettings() {
      ApiService.get(`${this.subMainRoute}/${this.data.document_id}`).then((response) => {
        this.data.status = response.data.data.data.status;
        this.details = [];
        // {
        //   "id": 20,
        //     "document_type_setting_id": 15,
        //     "table_name": "المبيعات",
        //     "table_slug": "sales_invoice",
        //     "column_name": "المجموع الفرعي",
        //     "column_slug": "invoice_subtotal",
        //     "key_id": 3,
        //     "key_slug": "sales",
        //     "type_routing": null,
        //     "natural_account_type": null,
        //     "account": null,
        //     "is_predefined": false,
        //     "is_active": true
        // }
        response.data.data.data.columns.forEach((row)=>{
          this.details.unshift({
            column: row.column_name,
            column_id: row.id,
            column_name: row.column_slug,
            general_account_id: row.type_routing == 1 ? ((row.account && row.account.id)? row.account.id : null) : null,
            special_account_id:  row.type_routing == 2 ? ((row.account && row.account.id)? row.account.id : null)  : null,
            special_account:  row.type_routing == 2 ? ((row.account && row.account.id)? {id: row.account.id,label: row.account.name}: null)  : null,
            type: 0,
            is_branch: 0,
            is_inventory: 0,
            is_customer: 0,
            is_vendor: 0,
            is_item: 0,
            is_currency: 0,
            account_type: row.natural_account_type ? row.natural_account_type : 1,//credit ,debit
            key_id: row.key_id ? row.key_id : null,
            key_slug: row.key_slug ? row.key_slug : null,
          });
        })

      });
    },
    async getDocumentsList() {
      await ApiService.get(`${this.mainRouteDependency}/document_type`, {params: {document_id: (this.doc_id ? this.doc_id : '')}}).then((response) => {
        this.documents_list = response.data.data;

        let _doc = response.data.data.find((row) => row.id == this.data.document_id);
        if (_doc) {
          this.getStatusList(_doc.doc_type);
          this.data.status = _doc.status;
          this.columns_doc_list = _doc.columns_doc;

        }
      });
    },

    getStatusList(id) {
      ApiService.get(this.mainRouteDependency + "/status_by_doc_type/" + id).then((response) => {
        this.status_list = response.data.data;
      });
    },

    getColumnsDocList(document_id) {
      ApiService.get(this.mainRouteDependency + "/document_type", {params: {document_id: (document_id ? document_id : '')}}).then((response) => {
        // this.columns_doc_list = response.data.data;
        let _doc = response.data.data.find((row) => row.id == this.data.document_id);
        if (_doc) {
          this.getStatusList(_doc.doc_type);
          this.data.status = _doc.status;
          this.columns_doc_list = _doc.columns_doc;

        }
      });
    },
    getJournalTypeList() {
      ApiService.get(this.mainRouteDependency + "/journal_type").then((response) => {
        this.journal_type_list = response.data.data;
      });
    },
    getAccountParents() {
      ApiService.get(`${this.mainRouteDependency}/${'account_parents'}`).then((response) => {
        this.accounts_list = response.data.data;
      });
    },

    getAccounts(filter) {
      if (filter && filter.length >= 3)
        return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
          this.accounts_leaf_list = response.data.data;
        });
    },
    async getJournalDependOn() {
      await ApiService.get(this.mainRouteDependency + `/journal_depend_on`).then((response) => {
          this.base_1 = response.data.data.filter((row)=> row.key == 'base_1');
          this.base_2 = response.data.data.filter((row)=> row.key == 'base_2');
      });
    },

    addItemRowToList() {
      this.details.unshift(this.details_form);
      this.details_form = {
        column: null,
        column_id: null,
        column_name: null,
        general_account_id: null,
        special_account_id: null,
        type: 0,
        is_branch: 0,
        is_inventory: 0,
        is_customer: 0,
        is_vendor: 0,
        is_item: 0,
        is_currency: 0,
        account_type: 1,//credit ,debit
        key_id: null,
        key_slug: null,
      };

    },
    removeItemRowFromList(index) {
      if (this.details.length > 1) {
        this.details.splice(index, 1);
      }
    },
    updateValue(index, key, event) {
      if (key == 'general_account_id' && event) {
        this.details[index]['special_account_id'] = null;
        this.details[index][key] = event
      }

      if (key == 'special_account_id' && event) {
        this.details[index]['general_account_id'] = null;
        this.details[index][key] = event ? event.id : null;
      }


    },
    loadOptions() {
    },
    selectColumn(index) {//column_name
      this.details[index]['column_name'] = this.details[index]['column'];
      this.setColumnName = this.details[index]['column_name'];
      this.details_form.column = this.setColumnName;
      this.details_form.column_name = this.setColumnName;
      this.details.forEach((row) => {
        row.column = this.details[index]['column'];
        row.column_name = this.details[index]['column'];
      });
      this.isDisabled = true;
    },
    changeDependentOn(index, column) {
      switch (column) {
        case 'is_customer':
          this.details[index]['is_customer'] = 1;
          this.details[index]['is_vendor'] = 0;
          this.details[index]['is_item'] = 0;
          break;
        case 'is_vendor':
          this.details[index]['is_vendor'] = 1;
          this.details[index]['is_customer'] = 0;
          this.details[index]['is_item'] = 0;
          break;
        case 'is_item':
          this.details[index]['is_item'] = 1;
          this.details[index]['is_customer'] = 0;
          this.details[index]['is_vendor'] = 0;
          break;
      }

    },
  },

  computed: {
    setDocType() {
      const _doc = this.documents_list.find((row) => row.id == this.data.document_id);
      // this.data.status = _doc.status;
      return _doc && _doc.columns_doc ? _doc.columns_doc : [];
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.journals_setting"), route: '/accounting/journals-setting'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getDocumentsList();
    this.getJournalTypeList();
    this.getAccountParents();
    this.getJournalDependOn();


    if (this.doc_id) {
      this.data.document_id = this.doc_id;

      // this.getStatusList(this.data.document_id);
      // this.data.status = _doc.status;

      // console.log(this.getStatusList(this.data.document_id));
    }

    if (this.idEdit) {
      this.getData();
    } else {
      this.addItemRowToList();
    }

  },
};
</script>


